import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

export default function Navbar(){
    const[isMenu, setIsMenu] = useState(false);
    const [scrollPosition, setScrollPosition] = useState(false);

    function openMenu(){
        if(!isMenu) setIsMenu(true);
        else setIsMenu(false);
    }

    const handleScroll = () => {
        const position = window.pageYOffset;
        if(position > 150) setScrollPosition(true);
        else setScrollPosition(false);
        console.log(position);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return(
        <nav className="fixed bg-lightblue w-full flex justify-center z-50">
            <div className={"flex items-center justify-between font-inter w-10/12 p-0 transition-all " + (scrollPosition ? "py-3" : "py-10")}>
                <div>
                    <Link to={"/"}>
                        <div className="flex items-center justify-center">
                            <img alt="slozimeto logo" src="img/logo.webp" />
                            <h3 className="text-lg text-logo font-bold"><Link to={"/"}>Slozimeto</Link><span className="font-normal">.to</span></h3>
                        </div>
                    </Link>
                </div>
                <div className="lg:flex items-center text-navbar gap-24 text-base hidden">
                    <HashLink to={"/#vyhody"}><a href="#vyhody">Výhody</a></HashLink>
                    <HashLink to={"/#sluzby"}><a href="#sluzby">Služby</a></HashLink>
                    <HashLink to={"/#galerie"}><a href="#galerie">Galerie</a></HashLink>
                    <HashLink to={"/#recenze"}><a href="#recenze">Reference</a></HashLink>
                    <Link to={"/cena-montaze-nabytku"}><button className="text-white font-mulish font-bold bg-maincolor rounded px-4 py-3">Odhad ceny zdarma</button></Link>
                </div>
                <div className="block lg:hidden overflow-x-hidden">
                    <input checked={isMenu} onClick={openMenu} type="checkbox" role="button" aria-label="Display the menu" class="menu" />
                    <div onClick={() => setIsMenu(false)} className={"absolute right-0 flex text-2xl pt-10 gap-4 flex-col items-center bg-lightblue w-full h-screen transition " + (isMenu? "":"translate-x-[1000px]")}>
                        <HashLink to={"/#vyhody"}><a href="#vyhody">Výhody</a></HashLink>
                        <HashLink to={"/#sluzby"}><a href="#sluzby">Služby</a></HashLink>
                        <HashLink to={"/#galerie"}><a href="#galerie">Galerie</a></HashLink>
                        <HashLink to={"/#recenze"}><a href="#recenze">Reference</a></HashLink>
                    </div>
                </div>
            </div>
        </nav>
    );
}