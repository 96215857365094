export default function Recenze(){
    return(
        <>
            <section id="recenze" className="h-full flex flex-col items-center justify-center font-mulish m-3 sm:m-10 mb-28">
                <div className="flex flex-col items-center justify-center font-mulish w-full">
                    <h2 className="text-2xl font-extrabold text-maincolor">RECENZE</h2>
                    <h3 className="text-2xl sm:text-5xl font-extrabold text-hcolor text-center">Co o mně říkají zákazníci</h3>
                    <div className="flex justify-center flex-wrap gap-7 m-5">
                        <Card name={"Martina Šedivá"} description={"Za slušné peníze jsem si nechala smontovat šatní skříň. Nemám co vytknout, vykonaná práce předešla mé očekávání. Ještě jednou moc děkuji a určitě doporučuji."} />
                        <Card name={"Michal Bednár"} description={"Montáž nábytku proběhla skvěle a neuvěřitelně rychle. Pán byl velmi profesionální a ochotný, udělal vše podle mých představ. Určitě doporučuji."} />
                        <Card name={"Patrik Petráš"} description={"Vše proběhlo perfektně, s kvalitou montáže jsem byl velice překvapen. Mladý pán byl přátelský a přijel na stanovený čas, jeho služby mohu vřele doporučit."} />
                    </div>
                </div>
            </section>
        </>
    );
}

function Card({name, description}){
    return(
        <div className="font-inter flex flex-col justify-between items-start gap-8 max-w-96 p-8 shadow-lg rounded">
            <div>
                <img alt="pět hvězdiček" loading="lazy" src="img/stars.webp" />
                <p className="text-text text-base">{description}</p>
            </div>
            <div className="flex items-center gap-7">
                <img alt="profilová fotografie" loading="lazy" src="img/profile-picture.webp" />
                <h4 className="text-hcolor sm:text-xl text-lg font-semibold">{name}</h4>
            </div>
        </div>
    );
}