export default function Sluzby(){
    return(
        <>
            <section id="sluzby" className="h-full flex flex-col items-center justify-center font-mulish m-2 sm:m-10">
                <div className="flex flex-col items-center justify-center font-mulish w-full lg:w-10/12">
                    <h2 className="text-2xl font-extrabold text-maincolor">SLUŽBY</h2>
                    <h3 className="text-2xl sm:text-5xl font-extrabold text-hcolor text-center">Jaký nábytek montuji?</h3>
                    <div className="flex flex-wrap gap-8 justify-center m-8">
                        <Card path={"img/satni-skrin.webp"} caption={"Šatní skříně"} description={"Postarám se o to, aby byla skříň pevně a správně sestavena, připravena pojmout vaše oblečení a doplňky."}/>
                        <Card path={"img/komoda.webp"} caption={"Komody"} description={"Zajistím pečlivé a stabilní sestavení, aby byla komoda připravena k používání a perfektně zapadla do interiéru."}/>
                        <Card path={"img/pribornik.webp"} caption={"Příborníky"} description={"Vaše drobné věci si zaslouží pečlivé uložení. Složím pro vás příborníky různých typů a designů."}/>
                        <Card path={"img/kancelarsky-nabytek.webp"} caption={"Kancelářský nábytek"} description={"Pracovní prostředí má vliv na produktivitu i pohodlí. Složím pro vás kancelářský nábytek, který splňuje vaše potřeby."}/>
                        <Card path={"img/postel.webp"} caption={"Postele"} description={"Složím pro vás postele různých typů a velikostí, zajišťujících komfortní spaní a kvalitní relaxaci."}/>
                        <Card path={"img/rozkladaci-pohovka.webp"} caption={"Rozkládací pohovky"} description={"Vaše pohovka bude pečlivě sestavena, aby splňovala vaše očekávání a poskytovala pohodlí a komfort."}/>
                    </div>
                </div>
            </section>
        </>
    );
}

function Card({path, caption, description}){
    return(
        <div className="font-inter flex flex-col items-start gap-3 max-w-96 p-8 shadow-lg rounded">
            <img alt="ikona" src={path} />
            <h4 className="text-hcolor text-2xl font-semibold">{caption}</h4>
            <p className="text-text text-base">{description}</p>
        </div>
    );
}