import { Link } from "react-router-dom";

export default function Home(){
    return(
        <section className="bg-lightblue min-h-screen font-mulish w-full flex justify-center">
            <div className="pt-36 flex flex-col w-10/12">
                <div className="flex">
                    <div className="w-full xl:w-1/2 flex flex-col justify-center items-center xl:items-start text-center xl:text-left xl:pl-36 gap-8">
                        <h1 className="text-4xl sm:text-6xl text-hcolor font-extrabold leading-tight">Nepřeplácejte za <br className="hidden xl:block"></br><span className="text-maincolor">montáž</span> nábytku</h1>
                        <div className="text-lg">
                            <h2 className="font-semibold text-text">Montuji nábytek ve Středočeském kraji.</h2>
                            <h3 className="font-bold"><Link to={"/montaz-nabytku-podebrady"}>PODĚBRADY</Link>  |  <Link to={"/montaz-nabytku-nymburk"}>NYMBURK</Link>  |  <Link to={"/montaz-nabytku-kolin"}>KOLÍN</Link>  |  <Link to={"/montaz-nabytku-kutnahora"}>KUTNÁ HORA</Link></h3> 
                        </div>
                        <button onClick={() => window.location.href = "#kontakt"} className="text-white font-mulish font-bold bg-maincolor rounded px-4 py-3">Odhad ceny zdarma</button>
                        <div>
                            <img className="m-auto lg:m-0" alt="pět hvězdiček" src="img/stars.webp" />
                            <p className="font-inter font-medium"><span className="font-bold">60+</span> Klientů hodnotilo mojí <br className="hidden xl:block"></br>práci 5 hvězdičkami.</p>
                        </div>
                    </div>
                    <div className="xl:flex w-1/2 justify-center items-center hidden">
                        <img alt="Dvě smontované skříně" src="img/home.webp" />
                    </div>
                </div>
                <div className="flex justify-center items-center m-5">
                    <div className="font-inter font-medium text-base flex flex-col md:flex-row md:gap-16 gap-3 text-hcolor">
                        <div className="flex flex-col items-center">
                            <p className="font-semibold text-4xl xl:text-6xl">60+</p>
                            <p>Provedených montáží</p>
                        </div>
                        <div className="flex flex-col items-center">
                            <p className="font-bold text-4xl xl:text-6xl green-dot">24 / 7</p>
                            <p>Dostupnost</p>
                        </div>
                        <div className="flex flex-col items-center">
                            <p className="font-semibold text-4xl xl:text-6xl">99 %</p>
                            <p>Spolehlivost kvality</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}