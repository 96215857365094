
export default function HomeFirma({ company }){
    return(
        <section className="bg-lightblue min-h-screen font-mulish w-full flex justify-center">
            <div className="pt-36 flex flex-col w-10/12">
                <div className="flex">
                    <div className="w-full xl:w-1/2 flex flex-col justify-center items-center xl:items-start text-center xl:text-left xl:pl-36 gap-8">
                        <h1 className="text-4xl sm:text-6xl text-hcolor font-extrabold leading-tight">Montáž nábytku <br className="hidden xl:block"></br>firmy <span className="text-maincolor">{company}</span></h1>
                        <div className="text-lg font-semibold text-text max-w-96">
                            <h2>Koupili jste si nový {company} nábytek a nevíte si rady s montáží? Nelamte si hlavu s nepřehledným návodem, od toho tady jsme my! Naše práce je rychlá, pečlivá a precizní.</h2>
                        </div>
                        <button onClick={() => window.location.href = "#kontakt"} className="text-white font-mulish font-bold bg-maincolor rounded px-4 py-3">Odhad ceny zdarma</button>
                        <div>
                            <img className="m-auto lg:m-0" alt="pět hvězdiček" src="img/stars.webp" loading="lazy" />
                            <p className="font-inter font-medium"><span className="font-bold">60+</span> Klientů hodnotilo mojí <br className="hidden xl:block"></br>práci 5 hvězdičkami.</p>
                        </div>
                    </div>
                    <div className="xl:flex w-1/2 justify-center items-center hidden">
                        <img alt="Smontovaná šatní skříň" loading="lazy" src="img/home.webp" />
                    </div>
                </div>
            </div>
        </section>
    );
}