import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom'
import { useEffect } from 'react';

import Footer from "./components/footer";
import Galerie from "./components/galerie";
import Home from "./components/home";
import Kontakt from "./components/kontakt";
import Navbar from "./components/navbar";
import Recenze from "./components/recenze";
import Sluzby from "./components/sluzby";
import Vyhody from "./components/vyhody";

import Nymburk from './components/mesta/nymburk';
import Podebrady from './components/mesta/podebrady';
import KutnaHora from './components/mesta/kutnahora';
import Kolin from './components/mesta/kolin';

import HomeFirma from './components/home-firma';
import HomeCena from './components/home-cena';

function App() {
  return (
    <Router>
      <Navbar />  
      
      <div className='w-screen'>
        <Routes>
          <Route path='/' element={<Zaklad />} />

          <Route path='/montaz-nabytku-podebrady' element={<MontazNabytkuPodebrady city={"Poděbradech"} footer={"PODĚBRADY"} />} />
          <Route path='/montaz-nabytku-nymburk' element={<MontazNabytkuNymburk city={"Nymburce"} footer={"NYMBURK"} />} />
          <Route path='/montaz-nabytku-kolin' element={<MontazNabytkuKolin city={"Kolíně"} footer={"KOLÍN"}/>} />
          <Route path='/montaz-nabytku-kutnahora' element={<MontazNabytkuKutnaHora city={"Kutné Hoře"} footer={"KUTNÁ HORA"}/>} />

          <Route path='/montaz-nabytku-ikea' element={<MontazNabytkuFirma company={"IKEA"} footer={"Nábytek IKEA"} />} />
          <Route path='/montaz-nabytku-jysk' element={<MontazNabytkuFirma company={"JYSK"} footer={"Nábytek JYSK"} />} />
          <Route path='/montaz-nabytku-sconto' element={<MontazNabytkuFirma company={"Sconto"} footer={"Nábytek Sconto"} />} />

          <Route path='/cena-montaze-nabytku' element={<CenaMontaze footer={"Cena montáže nábytku"} />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;

function Zaklad(){
  useEffect(() => {
    document.title = "Montáž nábytku ve Středočeském kraji - Slozimeto";
    document.querySelector('meta[name="description"]').content = `Spolehlivá montáž nábytku s osobním přístupem, garancí kvality a cenou na míru. - Slozimeto`
    document.querySelector('meta[name="keywords"]').content = `montáž nábytku, montáž nábytku Praha, profesionální montáž nábytku, složím nábytek, montáž skříňí, slozimeto.cz, montáž komoda, montáž postel, montáž kancelářského nábytku`
  }, [])

  return(
    <div className="w-full flex flex-col items-center bg-white">
          <Home />  
          <Vyhody />
          <Sluzby />
          <Galerie />
          <Recenze />
          <Kontakt />
          <Footer text={"S montáží nábytku to nechte na nás!"} />
      </div>
  );
}

function MontazNabytkuPodebrady({city, footer}){
  useEffect(() => {
    document.title = `Montáž nábytku v ${city} - Slozimeto`;
    document.querySelector('meta[name="description"]').content = `Montáž nábytku v ${city}. Postaráme se o precizní složení Vašeho nového nábytku. - Slozimeto.cz`
    document.querySelector('meta[name="keywords"]').content = `montáž nábytku ${city}, montáž nábytku v ${city}, složení nábytku v ${city}, montáž nábytku, slozimeto.cz`
  }, [])

  return(
    <div className="w-full flex flex-col items-center bg-white">
          <Podebrady /> 
          <Sluzby />
          <Kontakt />
          <Footer text={footer} />
      </div>
  );
}

function MontazNabytkuNymburk({city, footer}){
  useEffect(() => {
    document.title = `Montáž nábytku v ${city} - Slozimeto`;
    document.querySelector('meta[name="description"]').content = `Montáž nábytku v ${city}. Postaráme se o precizní složení Vašeho nového nábytku. - Slozimeto.cz`
    document.querySelector('meta[name="keywords"]').content = `montáž nábytku ${city}, montáž nábytku v ${city}, složení nábytku v ${city}, montáž nábytku, slozimeto.cz`
  }, [])

  return(
    <div className="w-full flex flex-col items-center bg-white">
          <Nymburk /> 
          <Sluzby />
          <Kontakt />
          <Footer text={footer} />
      </div>
  );
}

function MontazNabytkuKolin({city, footer}){
  useEffect(() => {
    document.title = `Montáž nábytku v ${city} - Slozimeto`;
    document.querySelector('meta[name="description"]').content = `Montáž nábytku v ${city}. Postaráme se o precizní složení Vašeho nového nábytku. - Slozimeto.cz`
    document.querySelector('meta[name="keywords"]').content = `montáž nábytku ${city}, montáž nábytku v ${city}, složení nábytku v ${city}, montáž nábytku, slozimeto.cz`
  }, [])

  return(
    <div className="w-full flex flex-col items-center bg-white">
          <Kolin /> 
          <Sluzby />
          <Kontakt />
          <Footer text={footer} />
      </div>
  );
}

function MontazNabytkuKutnaHora({city, footer}){
  useEffect(() => {
    document.title = `Montáž nábytku v ${city} - Slozimeto`;
    document.querySelector('meta[name="description"]').content = `Montáž nábytku v ${city}. Postaráme se o precizní složení Vašeho nového nábytku. - Slozimeto.cz`
    document.querySelector('meta[name="keywords"]').content = `montáž nábytku ${city}, montáž nábytku v ${city}, složení nábytku v ${city}, montáž nábytku, slozimeto.cz`
  }, [])

  return(
    <div className="w-full flex flex-col items-center bg-white">
          <KutnaHora /> 
          <Sluzby />
          <Kontakt />
          <Footer text={footer} />
      </div>
  );
}

function MontazNabytkuFirma({company, footer}){
  useEffect(() => {
    document.title = `Montáž nábytku od ${company} - Slozimeto`;
    document.querySelector('meta[name="description"]').content = `Profesionální montáž nábytku ${company}.Zajistíme precizní sestavení vašeho nábytku. - Slozimeto`
    document.querySelector('meta[name="keywords"]').content = `montáž ${company}, montáž nábytku ${company}, složení nábytku ${company}`
  }, [])

  return(
    <div className="w-full flex flex-col items-center bg-white">
          <HomeFirma company={company} />
          <Kontakt />
          <Footer text={footer} />
      </div>
  );
}

function CenaMontaze({footer}){
  useEffect(() => {
    document.title = `Kolik stojí montáž nábytku? - Slozimeto`;
    document.querySelector('meta[name="description"]').content = "Zjistěte ceny montáže nábytku na slozimeto.cz. Nabízíme cenově dostupné a transparentní služby s možností individuální cenové nabídky."
      document.querySelector('meta[name="keywords"]').content = `cena montáže nábytku, montáž nábytku ceník, montáž nábytku cena, náklady montáže nábytku, slozimeto.cz cena, cenová nabídka montáž nábytku`
  }, [])

  return(
    <div className="w-full flex flex-col items-center bg-white">
          <HomeCena />
          <Kontakt />
          <Footer text={footer} />
      </div>
  );
}