import { HashLink } from "react-router-hash-link";

export default function Footer({text}){
    return(
        <>
            <footer className="w-full bg-lightblue font-inter text-text p-10">
                <div className="flex flex-col lg:flex-row justify-evenly gap-7 lg:gap-0">
                    <div className="flex flex-col items-center lg:items-start gap-3">
                        <div className="flex items-center justify-center">
                            <img alt="slozimeto logo" loading="lazy" src="img/logo.webp" />
                            <a className="text-lg text-logo font-bold">Slozimeto<span className="font-normal">.to</span></a>
                        </div>
                        <p>Montáž nábytku - Středočeský kraj</p>
                        <p className="font-mulish font-bold text-hcolor">{text}</p>
                    </div>
                    <div className="flex flex-col sm:flex-row items-center justify-center lg:justify-between lg:w-1/3 gap-8 lg:gap-0 text-sm">
                        <div className="flex flex-col items-center sm:items-start gap-4">
                            <p className="text-hcolor font-semibold text-base">UŽITEČNÉ</p>
                            <HashLink to={"/#sluzby"}><a href="#sluzby">Moje služby</a></HashLink>
                            <HashLink to={"/#vyhody"}><a href="#vyhody">Proč vybrat mě?</a></HashLink>
                            <HashLink to={"/#recenze"}><a href="#recenze">Spokojení klienti</a></HashLink>
                        </div>
                        <div className="flex flex-col items-center sm:items-start gap-4">
                            <p className="text-hcolor font-semibold text-base">SLUŽBY</p>
                            <HashLink to={"/#sluzby"}><a href="#sluzby">Komody</a></HashLink>
                            <HashLink to={"/#sluzby"}><a href="#sluzby">Skříně</a></HashLink>
                            <HashLink to={"/#sluzby"}><a href="#sluzby">Postele</a></HashLink>
                        </div>
                        <div className="flex flex-col items-center sm:items-start gap-4">
                            <p className="text-hcolor font-semibold text-base">KONTAKT</p>
                            <div className="flex justify-center items-center gap-2">
                                <img alt="email" loading="lazy" src="img/footer-mail.webp"/>
                                <a href="mailto:info@slozimeto.cz">info@slozimeto.cz</a>
                            </div>
                            <div className="flex justify-center items-center gap-2">
                                <img alt="telefon" loading="lazy" src="img/footer-phone.webp"/>
                                <a href="tel:+420737229022">+420 737 229 022</a>
                            </div>
                            <div className="flex justify-center items-center gap-2">
                                <img alt="instagram" loading="lazy" src="img/footer-ig.webp"/>
                                <a href="https://www.instagram.com/slozimeto/" target="_blank">slozimetocz</a>
                            </div>
                        </div>
                    </div>
                </div>
                <hr class="h-px my-8 bg-gray-200 border-0 opacity-30 w-8/12 m-auto dark:bg-gray-700"></hr>
                <div className="flex flex-col sm:flex-row justify-around gap-3 sm:gap-0 text-sm">
                    <p className="font-semibold text-center text-xs sm:text-sm">2024 © Copyright Slozimeto. Všechna práva vyhrazena</p>
                    <div onClick={() => window.open("https://redesigner.cz/")} className="flex justify-center items-center gap-2">
                        <p>Vytvořil</p>
                        <img className="h-6" alt="Redesigner logo" loading="lazy" src="img/redesigner.webp" />
                    </div>
                </div>
            </footer>
        </>
    );
}