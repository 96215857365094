
export default function Vyhody(){
    return(
        <>
            <section id="vyhody" className="h-full flex flex-col items-center justify-center font-mulish m-10">
                <div className="flex flex-col items-center justify-center font-mulish w-full sm:w-10/12">
                    <h2 className="text-2xl font-extrabold text-maincolor">VÝHODY</h2>
                    <h3 className="text-2xl sm:text-5xl font-extrabold text-hcolor text-center lg:text-left">Proč si vybrat právě mě?</h3>
                    <div className="flex justify-center items-center w-full pt-12">
                        <div className="w-1/3 hidden 2xl:block">
                            <img alt="Těžce pracující člověk" src="img/pracovnik.webp" />
                        </div>
                        <div className="w:full 3xl:w-1/2">
                            <div className="flex flex-wrap justify-center gap-2 3xl:gap-24">
                                <Card path={"img/timer.webp"} caption={"Pohotovost"} description={"Na rozdíl od velkých firem jednám rychle a spolehlivě, abyste na montáž nemuseli čekat týdny."}/>
                                <Card path={"img/user-group.webp"} caption={"Osobní přístup"} description={"Ke každému přistupuji individuálně, naslouchám jeho požadavkům a přáním. Vaše spokojenost je mojí prioritou."}/>
                                <Card path={"img/money-add.webp"} caption={"Cena na míru"} description={"Každý projekt je jedinečný, nabízím individuální cenové nabídky přizpůsobené konkrétním požadavkům."}/>
                                <Card path={"img/mail-love.webp"} caption={"Garance kvality"} description={"Při montáži nábytku dbám na každý detail, abyste měli jistotu, že vše bude perfektně sestaveno."}/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

function Card({path, caption, description}){
    return(
        <div className="font-inter flex flex-col items-start gap-3 max-w-80">
            <img alt="ikona" src={path} />
            <h4 className="text-hcolor text-2xl font-semibold">{caption}</h4>
            <p className="text-text text-base">{description}</p>
        </div>
    );
}