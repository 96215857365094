import { useEffect, useState } from "react";


export default function Galerie(){
    let images = [ //sem stačí přidat další
        "img/galerie1.webp",
        "img/galerie2.webp",
        "img/galerie3.webp",
        "img/galerie4.webp",
        "img/galerie5.webp",
        "img/galerie6.webp"
    ]

    return(
        <>
            <section id="galerie" className="h-full flex flex-col items-center justify-center font-mulish m-5">
                <div className="flex flex-col items-center justify-center font-mulish">
                    <h2 className="text-2xl font-extrabold text-maincolor">UKÁZKA PRÁCE</h2>
                    <h3 className="text-2xl sm:text-5xl font-extrabold text-hcolor">Galerie</h3>
                    <Slider images={images} />
                </div>
            </section>
        </>
    );
}

function Slider({images}){
    const [index, setIndex] = useState({
        first: 0,
        second: 1,
        third: 2
    });
    const [currentImage, setCurrentImage] = useState({
        img1: null,
        img2: null,
        img3: null
    });

    useEffect(()=>{
        setCurrentImage({
            img1: images[index.first],
            img2: images[index.second],
            img3: images[index.third]
        })
    }, [index])

    function handleNext(){
        setIndex({
            first: indexCounter(index.first + 1),
            second: indexCounter(index.second + 1),
            third: indexCounter(index.third + 1)
        })
    }

    function handlePrevious(){
        setIndex({
            first: indexCounter(index.first - 1),
            second: indexCounter(index.second - 1),
            third: indexCounter(index.third - 1)
        })
    }

    function indexCounter(int){ 
        if(int == images.length){
            return(0);
        }
        else if(int < 0){
            return(images.length - 1);
        }
        else return int;
    }

    return(
        <div className="flex items-center justify-center gap-5 sm:m-10 my-5 select-none">
            <img onClick={handlePrevious} className="cursor-pointer" alt="předchozí" loading="lazy" src="img/arrow-left.webp"/>
            <div className="flex gap-5">
                <img className="" alt="Obrázek v galerii" loading="lazy" src={currentImage.img1}/>
                <img className="hidden lg:block" alt="Obrázek v galerii" loading="lazy" src={currentImage.img2}/>
                <img className="hidden 2xl:block" alt="Obrázek v galerii" loading="lazy" src={currentImage.img3}/>
            </div>
            <img onClick={handleNext} className="cursor-pointer" alt="další" loading="lazy" src="img/arrow-right.webp"/>
        </div>
    );
}