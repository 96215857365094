
export default function HomeCena(){
    return(
        <section className="bg-lightblue min-h-screen font-mulish w-full flex justify-center">
            <div className="pt-36 flex flex-col w-10/12">
                <div className="flex">
                    <div className="w-full xl:w-1/2 flex flex-col justify-center items-center xl:items-start text-center xl:text-left xl:pl-36 gap-8">
                        <h1 className="text-4xl sm:text-6xl text-hcolor font-extrabold leading-tight">Jaká je <span className="text-maincolor">cena</span><br className="hidden xl:block"></br><span className="text-maincolor">montáže</span> nábytku?</h1>
                        <div className="text-text">
                            <h2 className="font-semibold text-lg">Cena montáže nábytku se odvíjí od několika faktorů.</h2>
                            <div className="font-medium flex flex-col gap-6 pb-10">
                                <p>Důležitým faktorem je náročnost práce. Jednodušší nábytek, jako jsou police nebo obyčejné stoly, lze smontovat rychleji a levněji než složitější skříně nebo multifunkční nábytek, který vyžaduje více času a odborných dovedností.</p>
                                <p>Dalším důležitým faktorem je množství nábytku, které potřebujete smontovat. Větší objednávky mohou vést k množstevní slevě, zatímco menší zakázky budou mít standardní tarif. Každý projekt je také unikátní a záleží na specifických představách a požadavcích zákazníka.</p>
                                <p>Proto je nejlepší nás kontaktovat pro přesnou cenovou nabídku, která bude reflektovat vaše konkrétní požadavky.</p>
                            </div>
                        </div>                      
                    </div>
                    <div className="xl:flex w-1/2 justify-center items-center hidden">
                        <img alt="Smontované šatní skříně" loading="lazy" src="img/home.webp" />
                    </div>
                </div>
            </div>
        </section>
    );
}