export default function Kontakt(){
    return(
        <>
            <section id="kontakt" className="bg-maincolor w-full text-white flex justify-evenly p-5">
                <div className="flex flex-col justify-evenly items-center gap-7 sm:gap-0 lg:items-start">
                    <h3 className="text-4xl font-mulish font-semibold text-center lg:text-left">Zjistěte cenu montáže vašeho<br className="hidden lg:block"></br> nábytku <span className="font-extrabold">ZDARMA</span></h3>
                    <p className="font-inter text-lg opacity-80 hidden sm:block">Stačí mě kontaktovat přes telefon</p>
                    <a href="tel:+420737229022" className="text-maincolor bg-lightblue rounded-xl p-4 font-inter font-semibold flex items-center gap-4"><img src="img/phone.webp" loading="lazy" alt="telefon"/>+420 737 229 022</a>
                    <div className="sm:flex hidden items-center gap-1 text-lg">
                        <p className="font-inter">Nebo na e-mail:</p>
                        <img alt="email" loading="lazy" src="img/email.webp"/>
                        <a href="mailto:info@slozimeto.cz" className="font-mulish">info@slozimeto.cz</a>
                    </div>
                </div>
                <div>
                    <img className="hidden lg:block" alt="Dva páni podávající si ruce" loading="lazy" src="img/kontakt.webp" />
                </div>
            </section>
        </>
    );
}